<template>
  <v-col
    cols="12"
    md="4"
  >
    <app-card class="mt-4 text-center">
      <v-img
        class="rounded-circle elevation-6 mt-n12 d-inline-block"
        src="https://demos.creative-tim.com/vue-material-dashboard/img/marc.aba54d65.jpg"
        width="128"
      />

      <v-card-text class="text-center">
        <h6 class="text-h6 mb-2 text--secondary">
          CEO / FOUNDER
        </h6>

        <h4 class="text-h4 mb-3 text--primary">
          John Leider
        </h4>

        <p class="text--secondary">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ratione dolorem deserunt veniam tempora magnam quisquam quam error iusto cupiditate ducimus, et eligendi saepe voluptatibus assumenda similique temporibus placeat animi dicta?
        </p>

        <v-btn
          class="mr-0"
          color="primary"
          min-width="100"
          rounded
        >
          Follow
        </v-btn>
      </v-card-text>
    </app-card>
  </v-col>
</template>

<script>
  export default {
    name: 'AvatarContentProfile',
  }
</script>

<style scoped>
</style>
