<template>
  <v-container
    id="user-profile-view"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <form-profile />
      <avatar-content-profile />
    </v-row>
  </v-container>
</template>

<script>
  import FormProfile from '../modules/all/profile/components/FormProfile'
  import AvatarContentProfile from '../modules/all/profile/components/AvatarContentProfile'
  export default {
    name: 'Profile',
    components: {
      FormProfile,
      AvatarContentProfile,
    },
    // Title page
    metaInfo: {
      titleTemplate: 'Profile',
    },
  }
</script>
