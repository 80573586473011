<template>
  <v-col
    cols="12"
    md="8"
  >
    <material-card
      color="primary"
      icon="mdi-account-outline"
    >
      <template #title>
        Profile
      </template>

      <v-form>
        <v-container class="py-0">
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="dataProfile.email"
                disabled
                label="Email"
              />
            </v-col>

            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="dataProfile.role"
                disabled
                label="Role"
              />
            </v-col>

            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="dataProfile.fullName"
                disabled
                label="Full name"
              />
            </v-col>

            <v-col
              cols="12"
              class="text-right"
            >
              <v-btn
                color="primary"
                min-width="150"
              >
                Update Profile
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </material-card>
  </v-col>
</template>

<script>
  // Vuex
  import { get } from 'vuex-pathify'
  export default {
    name: 'FormProfile',
    computed: {
      // From Store
      ...get('userProfile', [
        'dataProfile',
      ]),
    },
    async created () {
      // call API fetchDataUserProfile from Store
      await this.$store.dispatch('userProfile/fetchDataUserProfile', this.getTokenLogin())
    },
  }
</script>

<style scoped>
</style>
